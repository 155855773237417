<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <modal
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalActive"
      :show.sync="show"
      :data="modalData"
      @modal-closed="handleModalClosed"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row
        align-v="center"
        class="justify-content-md-end mb-2"
      >
        <b-col md="auto">
          <b-button
            variant="primary"
            @click="modalEdit(respData)"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('Add Profile') }}
          </b-button>
        </b-col>
      </b-row>
      <div
        v-for="(item , index) in ranking"
        :key="index"
      >
        <b-card
          class="mb-2"
        >
          <b-row>
            <b-col cols="1">
              <b-avatar
                size="60"
              >
                <span style="font-size:16px;">{{ item.sequence }}</span>
              </b-avatar>
            </b-col>
            <b-col
              cols="10"
              class="d-flex align-items-center justify-content-start"
            >
              <span style="font-size:16px;">{{ item.name }}</span>
            </b-col>
            <b-col
              cols="1"
              class="d-flex align-items-center justify-content-end"
            > <feather-icon
              icon="TrashIcon"
              size="24"
              @click="deleteRanking(index)"
            /></b-col>
          </b-row>
        </b-card>
      </div>
      <!-- Action button -->
      <div class="d-flex mt-2">
        <b-button
          v-if="ranking.length > 0"
          variant="primary"
          class="d-flex mr-2"
          @click="validationForm()"
        >
          {{ $t("Save") }}
        </b-button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
  BAvatar,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import storeModule from '@/store/services/hero/ranking'
import Modal from './Modal.vue'

const STORE_MODULE_NAME = 'ranking'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Cleave,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BOverlay,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Modal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      name: '',
      detail: '',
      ranking: [],
      isModalActive: false,
      modalData: {},
      respData: null,
      required,
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    if (this.$route.params.id !== 'add') {
      const obj = {
        id: this.$route.params.id,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.respData = result.data.data
          console.log(this.respData)
          this.selectedData(this.respData)
          // console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    } else {
      this.newRanking()
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    deleteItem(item) {
      console.log(item)
    },
    handleModalClosed(returnedValue) {
      console.log('Modal closed with value:', returnedValue)
      this.newRanking(returnedValue)
    },
    modalEdit(item) {
      this.modalData = item
      this.isModalActive = true
    },
    newRanking(value) {
      this.ranking.push(
        {
          sequence: this.ranking.length + 1,
          profilePartnerId: value._id,
          name: `${value.firstName} ${value.lastName}`,
        },
      )
    },
    deleteRanking(i) {
      this.ranking.splice(i, 1)
      const pre_ranking = []
      for (let index = 0; index < this.ranking.length; index += 1) {
        const element = this.ranking[index]
        pre_ranking.push({
          sequence: index + 1,
          profilePartnerId: element.profilePartnerId,
          name: element.name,
        })
      }
      this.ranking = pre_ranking
      console.log(pre_ranking)
    },
    selectedData(data) {
      this.dataId = data._id
      this.name = data.name
      this.detail = data.detail
      if (data.rank.length > 0) {
        for (let index = 0; index < data.rank.length; index += 1) {
          const element = data.rank[index]
          this.ranking.push(
            {
              sequence: element.sequence,
              profilePartnerId: element.profilePartnerId._id,
              name: `${element.profilePartnerId.firstName} ${element.profilePartnerId.lastName}`,
            },
          )
        }
      }
      // this.ranking = data.rank
    },
    validationForm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            const obj = {
              id: this.dataId,
              rank: this.ranking,
            }
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/updateRanking`, obj)
              .then(result => {
                console.log('fetch Success : ', result)
                this.show = false
                // this.$router.push({ path: '/hero/queue/template/details' })
                this.$router.push({ path: '/hero/ranking' })
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Success'),
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: this.$t('Success'),
                  },
                })
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.show = false

                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
        })
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to cancel')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            // this.$router.push({ path: '/hero/queue/template/details' })
            this.$router.push({ path: '/hero/ranking' })
          }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
