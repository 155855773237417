<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Select Profile')"
    ok-title="Accept"
    size="xl"
    centered
    :no-close-on-backdrop="true"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-row>
      <b-col cols="12">

        <v-select
          id="register-province"
          v-model="profileSelected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="code"
          :options="respDataProfile"
          :reduce="(respDataProfile) => respDataProfile"
          class="select-size-lg"
        >
          <template
            slot="option"
            slot-scope="option"
          >

            {{ option.firstName }}
          </template>
          <template
            slot="selected-option"
            slot-scope="option"
          >
            {{ option.firstName }}
          </template>
        </v-select>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100">
        <div
          class="float-right"
        >

          <b-button
            v-if="profileSelected != null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="md"
            class="mr-2"
            @click="acceptModal()"
          >
            {{ $t('Accept') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="md"
            class="mr-2"
            @click="$emit('update:is-modal-active', false)"
          >
            {{ $t('Cancel') }}
          </b-button>

        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BCardText,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,

  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      profileSelected: null,
      required,
    }
  },
  computed: {
    respDataProfile() {
      return store.state[this.storeModuleName].respDataProfile != null ? store.state[this.storeModuleName].respDataProfile.data : []
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        const {
          _id, province, district, subDistrict,
        } = this.data
        this.dataId = _id
        if (province) { this.provinceId = province.code }
        if (district) { this.districtId = district.code }
        if (subDistrict) { this.subDistrictId = subDistrict.code }
        const obj = {
          provinceId: this.provinceId,
          districtId: this.districtId,
          subDistrictId: this.subDistrictId,
        }
        console.log(obj)
        store
          .dispatch(`${this.storeModuleName}/getProfile`, obj)
        // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.showLoading = false
          }).catch(error => {
            this.showLoading = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
  },
  created() {
    this.showLoading = true
  },
  methods: {
    acceptModal() {
      this.$emit('update:is-modal-active', false)
      this.$emit('modal-closed', this.profileSelected)
    },
    initValues() {
      this.dataId = null
      this.apiWebScraping = ''
      this.apiQRcode = ''
      this.apiIBanking = ''
      this.apiMobileBanking = ''
      this.apiCreditCard = ''
      this.apiATM = ''
    },
    switchChange(name, status) {
      console.log(name, status)
      const obj = {
        id: this.dataId,
        name,
        status,
      }
      store
        .dispatch(`${this.storeModuleName}/switchChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
