// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataProvince: null,
    respDataDistrict: null,
    respDataSubDistrict: null,
    respDataProfile: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_PROFILE(state, payload) {
      state.respDataProfile = payload
    },
    ADD(state, payload) {
      if (state.respData != null) {
        state.respData.data.push(payload)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max + 1
      } else {
        state.respData.data = [payload]
        state.respData.max = 1
      }
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
    SWITCH_CHANGE(state, item) {
      const ItemIndex = state.respData.data.findIndex(p => p._id === item.id)
      if (ItemIndex > -1) {
        const nameChild = item.name.split('.')
        console.log(nameChild)
        if (nameChild.length === 3) {
          state.respData.data[nameChild[0]][nameChild[1]][nameChild[2]] = item.status
        } else if (nameChild.length === 2) {
          state.respData.data[nameChild[0]][nameChild[1]] = item.status
        } else {
          state.respData.data[item.name] = item.status
        }
      }
    },
    GET_PROVINCE(state, item) {
      state.respDataProvince = item
    },
    GET_DISTRICT(state, item) {
      state.respDataDistrict = item
    },
    GET_SUB_DISTRICT(state, item) {
      state.respDataSubDistrict = item
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/ranking/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getProvince({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/ranking/getProvince')
          .then(response => {
            commit('GET_PROVINCE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getDistrict({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/ranking/getDistrict?provinceId=${item}`)
          .then(response => {
            commit('GET_DISTRICT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getSubDistrict({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/ranking/getSubDistrict?districtId=${item}`)
          .then(response => {
            commit('GET_SUB_DISTRICT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/ranking/view?id=${item.id}`)
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/ranking/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/ranking/update', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/hero/ranking/delete?id=${item}`)
          .then(response => {
            commit('REMOVE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    switchChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/hero/ranking/switchChange', item)
          .then(response => {
            commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getProfile({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/ranking/getProfile?provinceId=${item.provinceId}&districtId=${item.districtId}&subDistrictId=${item.subDistrictId}`)
          .then(response => {
            commit('GET_PROFILE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateRanking({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/ranking/updateRanking', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
